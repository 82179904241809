import { Box, Container, Paper, Typography } from "@mui/material";
import './ApplicationForm.scss';
import { useState } from "react";
import CustomInput from "../../common/Input/Input";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { emailService } from "../../common/SendEmail/EmailService";
import Swal from 'sweetalert2';
import { Circles } from "react-loader-spinner";
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const translationLanguages = [
    { value: 'ラトビア語', label: 'ラトビア語' },
    { value: 'アラビア語', label: 'アラビア語' },
    { value: 'イタリア語', label: 'イタリア語' },
    { value: 'インドネシア語', label: 'インドネシア語' },
    { value: 'ウクライナ語', label: 'ウクライナ語' },
    { value: 'オランダ語', label: 'オランダ語' },
    { value: 'スウェーデン語', label: 'スウェーデン語' },
    { value: 'スペイン語（ヨーロッパ）', label: 'スペイン語（ヨーロッパ）' },
    { value: 'スペイン語（ラテンアメリカ）', label: 'スペイン語（ラテンアメリカ）' },
    { value: 'タイ語', label: 'タイ語' },
    { value: 'デンマーク語', label: 'デンマーク語' },
    { value: 'ドイツ語', label: 'ドイツ語' },
    { value: 'ノルウェー語', label: 'ノルウェー語' },
    { value: 'フランス語', label: 'フランス語' },
    { value: 'フランス語（カナダ）', label: 'フランス語（カナダ）' },
    { value: 'ポルトガル語（ブラジル）', label: 'ポルトガル語（ブラジル）' },
    { value: 'ポルトガル語（ヨーロッパ）', label: 'ポルトガル語（ヨーロッパ）' },
    { value: 'ポーランド語', label: 'ポーランド語' },
    { value: 'リトアニア語', label: 'リトアニア語' },
    { value: 'ロシア語', label: 'ロシア語' },
    { value: '中国語（簡体）', label: '中国語（簡体）' },
    { value: '中国語（繁体）', label: '中国語（繁体）' },
    { value: '日本語', label: '日本語' },
    { value: '英語', label: '英語' },
    { value: '韓国語', label: '韓国語' },
];

const expertiseFields = [
    { value: 'アート、エンターテイメント', label: 'アート、エンターテイメント' },
    { value: 'サイエンス', label: 'サイエンス' },
    { value: 'ショッピング', label: 'ショッピング' },
    { value: 'スタイル、ファッション', label: 'スタイル、ファッション' },
    { value: 'スポーツ', label: 'スポーツ' },
    { value: 'テクノロジー', label: 'テクノロジー' },
    { value: 'ニュース', label: 'ニュース' },
    { value: 'ビジネス、産業', label: 'ビジネス、産業' },
    { value: 'ファイナンス', label: 'ファイナンス' },
    { value: 'ホビー、趣味', label: 'ホビー、趣味' },
    { value: '不動産', label: '不動産' },
    { value: '人事', label: '人事' },
    { value: '住まいと庭園', label: '住まいと庭園' },
    { value: '動物', label: '動物' },
    { value: '医療、健康、フィットネス', label: '医療、健康、フィットネス' },
    { value: '宗教、信仰', label: '宗教、信仰' },
    { value: '家族', label: '家族' },
    { value: '教育', label: '教育' },
    { value: '旅行', label: '旅行' },
    { value: '法律、政府、行政', label: '法律、政府、行政' },
    { value: '社会、カルチャー', label: '社会、カルチャー' },
    { value: '自動車', label: '自動車' },
    { value: '食べ物、飲み物', label: '食べ物、飲み物' },
];

const ApplicationForm = () => {
    const [formValues, setFormValues] = useState({
        lastName: '',
        firstName: '',
        email: '',
        nativeLanguage: '',
        languagePair1: '',
        languagePair2: '',
        languagePair3: '',
        expertise1: '',
        expertise2: '',
        expertise3: '',
        attachedFile1: null,
        attachedFile2: null
    });

    const requiredFields = {
        lastName: true,
        firstName: true,
        email: true,
        nativeLanguage: true,
        languagePair1: true,
        expertise1: true,
        attachedFile1: true,
    };

    const [hasErrors, setHasErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isVerification, setIsVerification] = useState(false);

    const validateFile = (file) => {
        const allowedExtensions = ['xlsx', 'xlsm', 'xls', 'doc', 'docx', 'docm', 'tex', 'ppt', 'pptx', 'pptm', 'zip', 'pdf', 'jpg', 'jpeg', 'gif', 'png'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        return allowedExtensions.includes(fileExtension);
    };

    const validateEmail = (value) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(value);
    };


    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setFormValues({ ...formValues, [name]: files ? files[0] : value });
        if (value) {
            setErrors({ ...errors, [name]: false });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};

        // Validate required fields based on requiredFields metadata
        Object.keys(requiredFields).forEach((key) => {
            if (requiredFields[key] && (!formValues[key] || formValues[key] === '')) {
                newErrors[key] = 'このフィールドは必須です';
            }
        });

        // Validate email format
        if (!validateEmail(formValues.email)) {
            newErrors.email = '有効なメールアドレスを入力してください';
        }

        // Validate file type
        if (formValues.attachedFile1 && !validateFile(formValues.attachedFile1)) {
            newErrors.attachedFile1 = '有効なファイル形式をアップロードしてください';
        }

        // Validate file type
        if (formValues.attachedFile2 && !validateFile(formValues.attachedFile2)) {
            newErrors.attachedFile2 = '有効なファイル形式をアップロードしてください';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setHasErrors(true);
            handleScrollIntoTop();
        } else {
            // Toggle to verification view
            setIsVerification(true);
            setHasErrors(false);
            // Scroll to the top of the form
            handleScrollIntoTop();
        }
    };

    const handleScrollIntoTop = () => {
        const element = document.getElementById('scroll-point');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    }

    const handleEdit = (event) => {
        event.preventDefault();
        setIsVerification(false);
        // Scroll to the top of the form
        handleScrollIntoTop();
    };

    const handleFinalSubmit = async (event) => {

        event.preventDefault();

        try {
            setIsLoading(true);
            const result = await emailService.sendEmailWithAttachment('register', formValues);

            if (result.success) {
                console.log('Estimate email sent successfully!');
                setIsLoading(false);

                MySwal.fire({
                    icon: 'success',
                    title: 'ご登録いただき、\nありがとうございます！',
                    text: '内容を確認後、今後の手順について メールにてご連絡いたします。',
                    confirmButtonText: 'ホームへ戻る >',
                    confirmButtonColor: '#F5894F',
                    willClose: () => {
                        window.location.href = '/';
                    }
                });
            } else {
                console.log('Failed to send email');
                setIsLoading(false);
                MySwal.fire({
                    icon: 'error',
                    title: '送信失敗',
                    text: '内容の送信に失敗しました。もう一度お試しください。',
                    confirmButtonText: '閉じる',
                    confirmButtonColor: '#F5894F'
                });
            }
        } catch (error) {
            console.error('Error sending email:', error);
            setIsLoading(false);
            MySwal.fire({
                icon: 'error',
                title: '送信失敗',
                text: '内容の送信に失敗しました。もう一度お試しください。',
                confirmButtonText: '閉じる',
                confirmButtonColor: '#F5894F'
            });
        }
    };

    return (
        isLoading ? (
            <>
                <div className="loader-overlay">
                    <Circles
                        height="80"
                        width="80"
                        color="#F5894F"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                </div>
            </>
        ) : (
            <Container className='application-container'>
                <div id='scroll-point' style={{ scrollMarginTop: '9rem' }}></div>
                <Paper className='application-form-container' elevation={3}>
                    <h2>翻訳者登録フォーム</h2>
                    <h3>APPLICATION FORM</h3>
                    {isVerification && (
                        <p className='form-verify-msg'>ご入力内容をご確認ください。</p>
                    )}
                    {hasErrors && (
                        <p color='error' className='form-error-message'>
                            ＊＊エラーを修正してください＊＊
                        </p>
                    )}
                    <Box component='form' onSubmit={handleSubmit} className='application-form'>
                        <CustomInput
                            label='姓（ローマ字で氏名を入力してください）'
                            name='lastName'
                            placeholder='例：YAMASHITA'
                            value={formValues.lastName}
                            onChange={handleChange}
                            required
                            error={!!errors.lastName}
                            errorMessage={errors.lastName}
                            disabled={isVerification}
                        />
                        <CustomInput
                            label='名（ローマ字で氏名を入力してください）'
                            name='firstName'
                            placeholder='例：TARO'
                            value={formValues.firstName}
                            onChange={handleChange}
                            required
                            error={!!errors.firstName}
                            errorMessage={errors.firstName}
                            disabled={isVerification}
                        />
                        <CustomInput
                            label='メールアドレス'
                            name='email'
                            placeholder='例：kobake@translations.com'
                            value={formValues.email}
                            onChange={handleChange}
                            required
                            error={!!errors.email}
                            errorMessage={errors.email}
                            disabled={isVerification}
                        />
                        <CustomInput
                            label='母国語'
                            name='nativeLanguage'
                            placeholder='例：英語'
                            type='select'
                            value={formValues.nativeLanguage}
                            onChange={handleChange}
                            required
                            error={!!errors.nativeLanguage}
                            errorMessage={errors.nativeLanguage}
                            options={translationLanguages}
                            disabled={isVerification}
                        />
                        <div className='dual-input'>
                            <label className='dual-input-label'>対応可能言語<span className='required'>*</span></label>
                            <CustomInput
                                label='言語ペア１'
                                name='languagePair1'
                                type='select'
                                placeholder='例：英語'
                                value={formValues.languagePair1}
                                onChange={handleChange}
                                required
                                error={!!errors.languagePair1}
                                errorMessage={errors.languagePair1}
                                options={translationLanguages}
                                disabled={isVerification}
                            />
                            <CustomInput
                                label='言語ペア２'
                                name='languagePair2'
                                type='select'
                                placeholder='例：日本語'
                                value={formValues.languagePair2}
                                onChange={handleChange}
                                options={translationLanguages}
                                disabled={isVerification}
                            />
                            <CustomInput
                                label='言語ペア３'
                                name='languagePair3'
                                type='select'
                                placeholder='例：中国語'
                                value={formValues.languagePair3}
                                onChange={handleChange}
                                options={translationLanguages}
                                disabled={isVerification}
                            />
                        </div>
                        <div className='dual-input'>
                            <label className='dual-input-label'>対応可能分野<span className='required'>*</span></label>
                            <CustomInput
                                label='対応可能分野１'
                                name='expertise1'
                                type='select'
                                placeholder='例：ニュース'
                                value={formValues.expertise1}
                                onChange={handleChange}
                                required
                                error={!!errors.expertise1}
                                errorMessage={errors.expertise1}
                                options={expertiseFields}
                                disabled={isVerification}
                            />
                            <CustomInput
                                label='対応可能分野２'
                                name='expertise2'
                                type='select'
                                placeholder='例：ビジネス、産業'
                                value={formValues.expertise2}
                                onChange={handleChange}
                                options={expertiseFields}
                                disabled={isVerification}
                            />
                            <CustomInput
                                label='対応可能分野３'
                                name='expertise3'
                                type='select'
                                placeholder='例：旅行'
                                value={formValues.expertise3}
                                onChange={handleChange}
                                options={expertiseFields}
                                disabled={isVerification}
                            />
                        </div>
                        <div className='dual-input'>
                            <label className='dual-input-label'>履歴書・経歴書<span className='required'>*</span></label>
                            <CustomInput
                                label='添付ファイル１'
                                type='file'
                                name='attachedFile1'
                                value={formValues.attachedFile1}
                                onChange={handleChange}
                                required
                                error={!!errors.attachedFile1}
                                errorMessage={errors.attachedFile1}
                                disabled={isVerification}
                            />
                            <CustomInput
                                label='添付ファイル２'
                                type='file'
                                name='attachedFile2'
                                value={formValues.attachedFile2}
                                onChange={handleChange}
                                error={!!errors.attachedFile2}
                                errorMessage={errors.attachedFile2}
                                disabled={isVerification}
                            />
                        </div>
                        <Typography variant='body2' className='file-notes'>
                            【注意事項】
                            <br />
                            ※対応ファイル形式は（.xlsx, .xlsm, .xls, .doc, .docx, .docm, .tex, .ppt, .pptx, .pptm, .zip, .pdf, .jpg, .jpeg, .gif, .png）となります。
                            <br />
                            ※1ファイルの容量の上限は5MBとなっております。1ファイルが5MBを超える際はメールアドレス、または記載の電話番号までご連絡下さい。
                            <br />
                            ※ファイルを添付する際は、必ずファイルを閉じてから『ファイル選択』をしてくださるようお願い致します。ファイルを開いているとデータは送付されません。
                            <br />
                            ※複数のファイルをお送りいただく場合は、可能な限りzip形式にて圧縮してお送りください。なお一つのzipファイルは5MB以下になるようにお願いいたします。
                            <br />
                            ※圧縮なしで複数のファイルをお送りいただく場合も、合計で12MB以下になるようにお願いいたします。
                            <br />
                            ※合計で12MBを超える際はメールアドレス、または記載の電話番号までご連絡下さい。
                            <br />
                            ※翻訳・校閲をご希望される原稿は、Microsoft OfficeのWord、Excel、PowerPoint、または、Tex、LaTeXの形式で保存されたファイルでお送りください。それ以外の形式のファイルの校閲をご希望される際は、コーディネーターにご相談ください。
                        </Typography>
                        <div className='application-btn-container'>
                            {!isVerification ? (
                                <button className='primary' onClick={handleSubmit}>
                                    内容を確認する <NavigateNextIcon />
                                </button>
                            ) : (
                                <>
                                    <button className='secondary' onClick={handleEdit}>
                                        <NavigateBeforeIcon /> 戻る
                                    </button>
                                    <button className='primary' onClick={handleFinalSubmit}>
                                        この内容で送信する <NavigateNextIcon />
                                    </button>
                                </>
                            )}
                        </div>
                    </Box>
                </Paper>
            </Container>
        )
    );
}

export default ApplicationForm;
