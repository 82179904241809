import Footer from '../common/Footer/Footer';
import Header from '../common/Header/Header';
import Banner from '../common/Banner/Banner';
import bannerImage from '../../content/images/banners/quotation.png';
import { Container } from '@mui/material';
import EstimateForm from './EsitmateForm/EstimateForm';
import './Estimate.scss';

const Estimate = () => {
    return (
        <>
            <Header />
            <Banner imagePath={bannerImage} />
            <Container className='estimate-content'>
                <p>お客様一人ひとりの細かなニーズやご希望を丁寧にヒアリングし、柔軟に対応いたします。</p>
                <p>専門分野に精通した翻訳者が文意を正確に把握した上で、丁寧に翻訳します。翻訳後は、徹底した品質管理を経て高品質な翻訳をお届けいたします。</p>
                <p>納期厳守とリーズナブルな料金でお客様に寄り添ったサービスを展開いたします。</p>
                <EstimateForm />
            </Container>
            <Footer />
        </>
    );
}

export default Estimate;