import './Flow.scss';
import flow1Icon from '../../../content/images/flow/flow1.png';
import flow2Icon from '../../../content/images/flow/flow2.png';
import flow3Icon from '../../../content/images/flow/flow3.png';
import flow4Icon from '../../../content/images/flow/flow4.png';
import flow5Icon from '../../../content/images/flow/flow5.png';
import flow6Icon from '../../../content/images/flow/flow6.png';
import flow7Icon from '../../../content/images/flow/flow7.png';

const flowSteps = [
    {
        step: 'STEP 01',
        title: 'お見積りご依頼',
        description: 'お見積りをご希望の方は、お気軽にお問い合わせください。原稿を添付していただければ、原稿の内容を精査し、よりお得な料金をご提示いたします。<br/>お見積りフォームや、メール、お電話でお問い合わせください。',
        buttonText: 'お見積りフォーム',
        buttonLink: '/estimate',
        buttonText2: 'メールはこちら',
        buttonLink2: '/contact',
        icon: flow1Icon
    },
    {
        step: 'STEP 02',
        title: '弊社からお見積り発行',
        description: 'お客様からご提供いただいた原稿と、お客様のご希望を精査し、弊社にてお見積もりを算出いたします。',
        icon: flow2Icon
    },
    {
        step: 'STEP 03',
        title: '打ち合わせ・ヒアリング',
        description: 'お見積りの内容によっては、弊社からお客様のご依頼内容やご要望をより詳しく確認させていただくことがあります。また、お客様のご要望に合わせて、お見積りを再計算することも可能です。',
        icon: flow3Icon
    },
    {
        step: 'STEP 04',
        title: '正式ご発注',
        description: 'メールや電話で正式にご発注を承ります。',
        icon: flow4Icon
    },
    {
        step: 'STEP 05',
        title: '翻訳作業開始',
        description: 'お見積り内容や事前のヒヤリング内容に合わせて最適な翻訳者を手配し、翻訳を開始します。',
        icon: flow5Icon
    },
    {
        step: 'STEP 06',
        title: '品質管理',
        description: '翻訳が完了したら、お客様のご要望をしっかりと把握し、丁寧にチェックと校正を行います。これにより、翻訳の精度を最大限に引き上げます。',
        icon: flow6Icon
    },
    {
        step: 'STEP 07',
        title: '品質管理後、お客様へ納品',
        description: 'お客様のご要望に合わせて翻訳文の体裁を整え、ご希望の形式にて納品いたします。',
        icon: flow7Icon
    }
];

const Flow = () => {
    return (
        <div className='flow'>
            <h2>利用の流れ</h2>
            <h3>SERVICE FLOW</h3>
            <div className='flow-content'>
                {flowSteps.map((step, index) => (
                    <div className='flow-item' key={index}>
                        <img src={step.icon} alt={`Step ${index + 1}`} />
                        <div className='flow-text'>
                            <h4>{step.step}</h4>
                            <h5>{step.title}</h5>
                            <p dangerouslySetInnerHTML={{ __html: step.description }}></p>
                            <div className='btn-container'>
                                {step.buttonText && (
                                    <button className='primary' onClick={() => window.location.href = step.buttonLink}>
                                        {step.buttonText}
                                    </button>
                                )}
                                {step.buttonText2 && (
                                    <button className='primary' onClick={() => window.location.href = step.buttonLink2}>
                                        {step.buttonText2}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Flow;
