// Helper function to generate the <head> section (common for all emails)
const generateEmailHead = () => {
    return `
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f4f4f4;
                margin: 0;
                padding: 20px;
            }
            .container {
                background-color: #ffffff;
                border-radius: 8px;
                padding: 20px;
                max-width: 600px;
                margin: 0 auto;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            }
            h1 {
                color: #333;
                font-size: 24px;
                margin-bottom: 20px;
            }
            p {
                font-size: 16px;
                color: #555;
                line-height: 1.5;
            }
            .details {
                background-color: #f9f9f9;
                border: 1px solid #ddd;
                padding: 10px;
                border-radius: 4px;
            }
            .details p {
                margin: 5px 0;
                font-size: 14px;
                color: #333;
            }
        </style>
    </head>
    `;
};

// Helper function to generate the <body> section based on the form type
const generateEmailBody = (type, formData) => {
    switch (type) {
        case 'estimate':
            return `
            <body>
                <div class="container">
                    <h1>新しいお見積り依頼</h1>
                    <p>以下の内容で新しいお見積り依頼が届きました。</p>
                    <div class="details">
                        <p><strong>氏名:</strong> ${formData.firstName} ${formData.lastName} (${formData.firstNameKana} ${formData.lastNameKana})</p>
                        <p><strong>会社名:</strong> ${formData.companyName}</p>
                        <p><strong>所属部署:</strong> ${formData.department}</p>
                        <p><strong>メールアドレス:</strong> ${formData.email}</p>
                        <p><strong>電話番号:</strong> ${formData.phone}</p>
                        <p><strong>原文の言語:</strong> ${formData.sourceLanguage}</p>
                        <p><strong>翻訳後の言語:</strong> ${formData.targetLanguage}</p>
                        <p><strong>ご希望の納期:</strong> ${formatDateToJapanese(formData.date)}</p>
                        <p><strong>コメント:</strong> ${formData.comments}</p>
                    </div>
                    ${formData.file ? `<p>添付ファイルをご確認ください。</p>` : ''}
                </div>
            </body>
            `;

        case 'contact':
            return `
            <body>
                <div class="container">
                    <h1>新しいお問い合わせ</h1>
                    <p>以下の内容でお問い合わせが届きました。</p>
                    <div class="details">
                        <p><strong>氏名:</strong> ${formData.firstName} ${formData.lastName} (${formData.firstNameKana} ${formData.lastNameKana})</p>
                        <p><strong>会社名:</strong> ${formData.companyName}</p>
                        <p><strong>メールアドレス:</strong> ${formData.email}</p>
                        <p><strong>電話番号:</strong> ${formData.phone}</p>
                        <p><strong>お問い合わせ内容:</strong> ${formData.inquiry}</p>
                    </div>
                </div>
            </body>
            `;

        case 'register':
            return `
            <body>
                <div class="container">
                    <h1>新しい翻訳者登録</h1>
                    <p>以下の内容で翻訳者登録が届きました。</p>
                    <div class="details">
                        <p><strong>氏名（ローマ字）:</strong> ${formData.firstName} ${formData.lastName}</p>
                        <p><strong>メールアドレス:</strong> ${formData.email}</p>
                        <p><strong>母国語:</strong> ${formData.nativeLanguage}</p>
                        <p><strong>対応可能言語ペア１:</strong> ${formData.languagePair1}</p>
                        <p><strong>対応可能言語ペア２:</strong> ${formData.languagePair2 ? formData.languagePair2 : 'なし'}</p>
                        <p><strong>対応可能言語ペア３:</strong> ${formData.languagePair3 ? formData.languagePair3 : 'なし'}</p>
                        <p><strong>対応可能分野１:</strong> ${formData.expertise1}</p>
                        <p><strong>対応可能分野２:</strong> ${formData.expertise2 ? formData.expertise2 : 'なし'}</p>
                        <p><strong>対応可能分野３:</strong> ${formData.expertise3 ? formData.expertise3 : 'なし'}</p>
                    </div>
                    <p>履歴書・経歴書のファイルが添付されています。添付ファイルをご確認ください。</p>
                </div>
            </body>
            `;

        default:
            throw new Error('Unknown email type');
    }
};

// Helper function to generate simplified Thank You Email content
const prepareThankYouEmail = (type, formData) => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}年${String(today.getMonth() + 1).padStart(2, '0')}月${String(today.getDate()).padStart(2, '0')}日`;
    let subject, message;

    switch (type) {
        case 'estimate':
            subject = `【翻訳のコバケより】お見積りのご依頼をいただき、ありがとうございました`;
            message = `
            <body>
                <div class="container">
                    ${formData.companyName ? `<p>${formData.companyName}株式会社</p>` : ''}
                    <p>${formData.lastName} ${formData.firstName}様</p>

                    <p>この度は、お見積りのご依頼をいただき、誠にありがとうございます。</p>
                    <p>お見積りの内容を確認のうえ、担当者より改めてご連絡させていただきます。</p>

                    <p>ご多忙のところ恐縮ですが、今しばらくお待ちいただけますようお願い申し上げます。</p>

                    <p>Kobake翻訳サービス<br/>${formattedDate}</p>
                </div>
            </body>
            `;
            break;

        case 'contact':
            subject = `【翻訳のコバケより】お問い合わせいただき、ありがとうございました`;
            message = `
            <body>
                <div class="container">
                    <p>${formData.companyName}株式会社</p>
                    <p>${formData.lastName} ${formData.firstName}様</p>

                    <p>この度は、お問い合わせいただき、誠にありがとうございます。</p>
                    <p>お問い合わせの内容を確認のうえ、担当者より改めてご連絡させていただきます。</p>

                    <p>ご多忙のところ恐縮ですが、今しばらくお待ちいただけますようお願い申し上げます。</p>

                    <p>Kobake翻訳サービス<br/>${formattedDate}</p>
                </div>
            </body>
            `;
            break;

        case 'register':
            subject = `【翻訳のコバケより】弊社へのご登録申請をいただき、ありがとうございました`;
            message = `
            <body>
                <div class="container">
                    <p>${formData.lastName} ${formData.firstName}様</p>

                    <p>この度は、弊社へのご登録申請をいただき、誠にありがとうございます。</p>
                    <p>お送りいただいた情報を確認し、登録手続きを進めさせていただきます。</p>

                    <p>ご不明点がございましたら、改めてご連絡いたしますので、何卒よろしくお願い申し上げます。</p>

                    <p>Kobake翻訳サービス<br/>${formattedDate}</p>
                </div>
            </body>
            `;
            break;

        default:
            throw new Error('Unknown email type');
    }

    return { subject, message };
};

const formatDateToJapanese = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
};

const emailService = {
    sendEmail: async (to, subject, message, attachments = [], type = false) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BE_URL}/api/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: to,
                    subject: subject,
                    message: message,
                    attachments: attachments
                }),
            });

            const result = await response.json();
            return result;
        } catch (error) {
            console.error('Error sending email:', error);
            throw error; // Propagate the error to handle in calling functions
        }
    },

    sendEmailWithAttachment: async (type, formData) => {
        const formattedDate = formatDateToJapanese(new Date());
        const subjectMap = {
            estimate: `新しいお見積り依頼 - ${formattedDate} - ${formData.lastName} ${formData.firstName}`,
            contact: `新しいお問い合わせ - ${formattedDate} - ${formData.lastName} ${formData.firstName}`,
            register: `新しい翻訳者登録 - ${formattedDate} - ${formData.lastName} ${formData.firstName}`
        };

        const subject = subjectMap[type];
        if (!subject) {
            throw new Error('Unknown email type');
        }

        // Admin Email Content
        const adminMessage = `
        <!DOCTYPE html>
        <html lang="ja">
            ${generateEmailHead()}
            ${generateEmailBody(type, formData)}
        </html>
        `;

        const emailData = new FormData();
        emailData.append('to', process.env.REACT_APP_KOBAKE_EMAIL); // Admin email
        emailData.append('subject', subject);
        emailData.append('message', adminMessage);

        // Attach file if available (for the estimate form)
        if (formData.file) {
            emailData.append('file', formData.file);
        }

        // Attach additional files if available
        if (formData.attachedFile1) {
            emailData.append('attachedFile1', formData.attachedFile1);
        }
        if (formData.attachedFile2) {
            emailData.append('attachedFile2', formData.attachedFile2);
        }

        emailData.append('type', type);
        emailData.append('formData', JSON.stringify(formData));

        try {
            // Send the email to admin
            await fetch(`${process.env.REACT_APP_BE_URL}/api/send-email`, {
                method: 'POST',
                body: emailData,
            });

            // Prepare and send the thank you email to the user
            const { subject: userSubject, message: userMessage } = prepareThankYouEmail(type, formData);

            const userEmailData = new FormData();
            userEmailData.append('to', formData.email); // User's email
            userEmailData.append('subject', userSubject);
            userEmailData.append('message', userMessage);

            const response = await fetch(`${process.env.REACT_APP_BE_URL}/api/send-email`, {
                method: 'POST',
                body: userEmailData,
            });
            const result = await response.json();
            return result;
        } catch (error) {
            console.error('Error sending email:', error);
            throw error; // Propagate the error to handle in calling functions
        }
    }

};

export { emailService };